@import "src/styles/settings.scss";

.footer {
  @include flex-column;
  width: 100%;
  background-color: #313131;
  margin-top: 50px;
  border-top: 1px solid rgba(82, 82, 82, 0.83);

  img {
    width: 200px;
  }

  .container {
    padding-bottom: 50px;
    flex-direction: column;
    @include flex-center;
    justify-content: space-between;
    margin-top: 32px;
    gap: 10px;

    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center
    }

    @include mobile {
      @include flex-column;
    }

    @include tablet {
      width: calc(100% - 40px);
    }
    @include laptop {
      width: calc(100% - 40px);
    }

    .title {
      font-weight: 300;
      text-align: start;

      @include mobile {
        text-align: center;
      }
    }

    .contact {
      @include flex-column;
      gap: 16px;
      align-items: flex-start;

      a {
        font-weight: 400;
        @include flex-center;
        gap: 8px;
        font-size: 1.125rem;
        transition: all 0.2s ease;

        &::after {
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 0;
          height: 1px;
          background: $pennBlue;
          transition: all 0.2s ease;
          border-radius: 1px;
        }

        &:hover {
          color: $mainAccent;
        }

        &:hover::after {
          width: 100%;
        }
      }
    }
    .icon {
      background-color: $uranianBlue;
      border-radius: 50%;
      padding: 20px;
    }

    .copy {
      font-size: 12px;
      color: $backgroundOpacity;
    }
  }
  .legal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: rgb(145, 145, 145);

    a {
      &:hover {
        color: $mainAccent;
        text-decoration: underline;
      }
    }

    @include mobile {
      width: 100%;
      margin-top: 25px;
    }
  }
}
