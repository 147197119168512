@import "src/styles/settings.scss";

.mainPage {
  position: relative;
  height: 650px;
  width: 100%;
  overflow: hidden;

  .image {
    height: inherit;
    width: inherit;
    position: absolute;
    z-index: -1;

    img {
      width: inherit;
      height: inherit;
      object-fit: cover;
      filter: brightness(50%);
    }
  }

  .content {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;

    .text {
      text-align: center;

      h1,
      h2 {
        margin: 10px 0;
      }

      h1 {
        font-size: 64px;
        font-weight: 300;
      }

      h2 {
        font-weight: 300;
      }
    }
  }
}
