.lessonPlan {
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  .lessonTile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
    width: 100%;
    padding: 5px;

    p {
      margin: 0;
      font-size: 0.6rem;
    }

    .subject {
      font-weight: bold;
      font-size: 0.7rem;
      padding-bottom: 8px;
    }
  }

  .lessons {
    display: flex;
  }
}
