@import "src/styles/settings.scss";

.description {
  @include flex-column;
  gap: 16px;
  justify-content: flex-start;
  cursor: default;
  padding: 60px 35px;
  margin-bottom: 55px;
  background-color: $pennBlue;
  border-radius: $borderRadius;

  .text {
    position: relative;
    padding: 45px 0;

    p {
      font-size: 1.25rem;
      text-align: center;
    }
  }

  .steps {
    @include flex-column;
    border-radius: 8px;
    padding: 45px 0;

    strong {
      font-size: 1.5rem;
      line-height: 2;
      font-weight: 500;
      margin-bottom: 20px;
      text-align: center;
    }

    .stepsWrapper {
      display: flex;
      flex-wrap: wrap;
      height: 360px;
      gap: 20px;
      height: 100%;
      justify-content: space-around;

      .step {
        flex: 1;
        min-width: 250px;
        max-width: 300px;
        text-align: center;
        box-shadow: 0px 5px 40px 0px rgba(107, 108, 109, 0.337);
        background-color: $warning;
        border-top: $borderLeft;
        border-radius: $borderRadius;

        h4 {
          margin: 20px 0;
        }

        strong {
          display: block;
          font-size: 1.25rem;
          margin-bottom: 10px;
        }

        .stepDescription {
          background-color: $background;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          padding: 15px;
          height: calc(360px - 40px);
        }

        p {
          font-size: 1.11rem;
          margin: 0;
        }
      }
    }
  }
}
