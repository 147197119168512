@import "src/styles/settings.scss";

.banner {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 160px 0;
  height: 450px;

  .shape {
    position: absolute;
    height: 750px;
    width: 750px;
    background-color: $warning;
    right: -400px;
    top: 0;
    transform: rotate(45deg)
  }

  @include mobile {
    @include flex-column;
  }

  @include tablet {
    @include flex-column;
  }

  .image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(1px) brightness(100%);
      transform: scale(1.01);
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;

    @include mobile {
      width: calc(100% - 40px);
    }

    @include tablet {
      width: calc(100% - 40px);
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h1,
      h2 {
        margin: 10px 0;
      }

      h1 {
        font-weight: 300;
        padding-bottom: 16px;
        font-size: 5rem;
        font-weight: bold;

        @include tablet {
          font-size: 4rem;
        }

        @include mobile {
          font-size: 4rem;
        }
      }

      h2 {
        padding-bottom: 20px;
        font-weight: 300;
        text-align: center;

        @include mobile {
          font-size: 2.5rem;
        }

        &::after {
          content: none;
        }
      }

      p {
        font-size: 1.2rem;
      }
    }
  }
}
