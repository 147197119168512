@import "../../styles/settings.scss";

.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  color: #333;
  background-color: #f8f9fa;
  position: relative;

  &Content {
    max-width: 600px;
    padding: 60px 90px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
  }

  &Header {
    font-size: 6rem;
    margin: 0;
    color: #dc3545;
  }

  &Text {
    font-size: 1.75rem;
    color: #495057;
    margin: 30px 0;
  }
}
