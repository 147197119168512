@import "./styles/settings.scss";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: $roboto;
  font-weight: 300;
  background-color: $background;
  color: rgb(231, 231, 231);

  a {
    color: inherit;
    text-decoration: none;
  }

  h2 {
    font-size: 4rem;
    font-weight: 300;
    position: relative;
    text-align: center;
  
    &::after {
      content: "";
      position: absolute;
      height: 4px;
      border-radius: 4px;
      background-color: $warning;
      width: 100%;
      z-index: 0;
      left: 0px;
      bottom: -7px;
    }
  }

  
  h3 {
    font-size: 3rem;
    text-align: center;
    font-weight: 300;
  }
}


