@import "../../styles/settings.scss";

.container {
  padding: 90px 35px;
  cursor: default;
  background-color: $pennBlue;
  border-radius: $borderRadius;

  .title {
    margin-top: 0;
    margin-bottom: 60px;
  }

  .text {
    font-size: 1.25rem;
    line-height: 2;
  }
}

.capability {
  margin: 24px 0;
  padding: 10px;
  background-color: $background;
  text-align: center;
  border-left: $borderLeft;
  border-radius: $borderRadius;

  h4 {
    font-weight: 300;
    font-size: 2rem;
    margin: 10px 0;
  }

  p {
    font-style: italic;
  }
}