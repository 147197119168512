@import "../../../../styles/settings.scss";

.singlePlan {
  padding-bottom: 25px;
  .planTitle {
    width: 100%;
    @include flex-center;
    justify-content: space-between;
  }
  .evaluationRate {
    padding: 5px;
    border-radius: 5px;
  }
}
