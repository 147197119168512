@import "src/styles/settings.scss";

.navigation {
  padding: 20px 0;
  background-color: #313131;
  border-bottom: 1px solid rgba(82, 82, 82, 0.83);

  .divider {
    @media screen and (max-width: $tablet) {
      display: none;
    }
  }

  .mainNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .logo {
      img {
        max-height: 100px;
      }
    }

    .nav {
      display: flex;
      gap: 20px;
      position: relative;

      .menuIcon {
        display: none;
        @media screen and (max-width: $tablet) {
          display: block;
          cursor: pointer;
        }
      }

      .menuItem {
        @media screen and (max-width: $tablet) {
          display: none;
        }
      }

      a {
        text-decoration: none;
        font-weight: 500;

        &:hover {
          color: $mainAccent;
          opacity: 0.7;
        }
      }
    }
  }

  .userNav {
    padding: 10px 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $tablet) {
      display: none;
    }

    .profile {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .loggedIn {
        display: flex;
        align-items: center;

        p {
          margin: 0;
          font-size: 0.85rem;
        }

        Button {
          font-size: 0.9rem;
        }
      }
    }

    .policy {
      width: 250px;
      display: flex;
      justify-content: space-between;

      a {
        text-decoration: none;
        font-weight: 500;

        &:hover {
          color: $mainAccent;
          opacity: 0.7;
        }
      }
    }
  }
}
