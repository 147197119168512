@import "src/styles/settings.scss";

.arrow {
  z-index: 10;
  @include flex-center;
  height: 50px;
  width: 50px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  background-color: $mainAccentOpacity;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  @include tablet {
    width: 50px;
    height: 50px;
  }
  @include mobile {
    width: 50px;
    height: 50px;

    bottom: 20px;
    right: 20px;
  }

  a {
    height: 32px;
    @include mobile {
      height: 24px;
    }
    @include tablet {
      height: 24px;
    }
  }
}

.arrow.visible {
  @include flex-center;
  opacity: 1;
  visibility: visible;
  &:hover {
    opacity: .7;
  }
}
