.chipWrapper {
  position: relative;
  display: inline-flex;
}

.chip .MuiChip-root {
  position: relative;
  overflow: visible;
}

.chip .MuiChip-root .MuiChip-deleteIcon {
  display: none !important;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.chip:hover .MuiChip-root .MuiChip-deleteIcon {
  display: inline-flex !important;
}
