@import "../../App.scss";

.container {
  @include flex-center;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 24;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  width: 100%;
}

.title {
  margin-bottom: 20px;
}

.submitButton {
  margin-top: 20px;
  width: 100%;
}

.closeButton {
  margin-top: 10px;
}

.thanks {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 24;
  padding: 20px;
  @include flex-column;
  gap: 20px;
}
