@import "src/styles/settings.scss";

.functionalitiesWrapper {
  background-color: $pennBlue;
  margin-bottom: 65px;
  padding: 15px 35px;
  border-radius: $borderRadius;

  .container {
    margin-bottom: 65px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    cursor: default;

    .graphic {
      position: absolute;
      opacity: 0.7;
      bottom: -90px;
      left: -600px;
    }

    .category {
      padding: 20px;
      position: relative;
      max-width: 280px;
      height: 280px;
      border-left: $borderLeft;
      border-radius: $borderRadius;
      background-color: $background;

      .icon {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
  }

  .title {
    font-weight: 300;
    padding-top: 55px;
  }
}
