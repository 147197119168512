@import "src/styles/settings.scss";

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  .timetables {
    width: 90%;
  }
}

.feedbackBox {
  position: relative;

  width: 100%;
}

.feedbackBoxMain {
  position: fixed;
  bottom: 10px;
  right: 10px;
}
